import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/template/Layout"
import Button from "../components/atoms/Button"

const IndexPage = () => {
  return (
    <Layout seoTitle="Home" showSubMenu={false}>
      <div className="flex justify-center my-10">
        <Button size="small" color="primary" className="cursor-pointer">
          <Link to="/de/country/tansania">Country View</Link> <br />
        </Button>
      </div>
    </Layout>
  )
}

export default IndexPage
